//------------------------------------------
// Colors
//------------------------------------------

//primary material design colors
$primaryLight:  material-color('blue', '200');
$primaryMedium: material-color('blue', '500');
$primaryDim:    material-color('blue', '700');
$primaryDark:   material-color('blue', '900');

//secondary (accent) material design colors
$accentLight:  material-color('pink', '100');
$accentMedium: material-color('pink', '200');
$accentDim:    material-color('pink', '400');
$accentDark:   material-color('pink', '700');

//neutral/grey
$neutral50:  material-color('grey', '50');
$neutral100: material-color('grey', '100');
$neutral200: material-color('grey', '200');
$neutral300: material-color('grey', '300');
$neutral400: material-color('grey', '400');
$neutral500: material-color('grey', '500');
$neutral600: material-color('grey', '600');
$neutral700: material-color('grey', '700');
$neutral800: material-color('grey', '800');
$neutral900: material-color('grey', '900');

//text colors -> (white) light on dark
$primaryTextLight:   rgba(255,255,255,1);
$secondaryTextLight: rgba(255,255,255,0.7);
$hintsTextLight:     rgba(255,255,255,0.3);

//text colors -> (black) dark on light
$primaryTextDark:   rgba(0,0,0,0.87);
$secondaryTextDark: rgba(0,0,0,0.54);
$hintsTextDark:     rgba(0,0,0,0.26);

//font size headers
$h1-fontsize: 4.2rem;
$h2-fontsize: 3.56rem;
$h3-fontsize: 2.92rem;
$h4-fontsize: 2.28rem;
$h5-fontsize: 1.64rem;
$h6-fontsize: 1rem;

//font weight headers
$h1-weight: 200;
$h2-weight: 400;
$h3-weight: 400;
$h4-weight: 400;
$h5-weight: 400;
$h6-weight: 400;
